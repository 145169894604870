















































import Vue from 'vue'

export default Vue.extend({
  name: 'WbgtAndHeatIndex',
  props: {
    displayWBGTInHeader: {
      type: Boolean
    },
    displayHeatIndexInHeader: {
      type: Boolean
    },
    lastTimeUpdated: {
      type: String // Same for WBGT and Heat Index
    },
    avgHeatIndex: {
      type: [Number, String],
      default: '--'
    },
    lastWBGT: {
      type: [Number, String],
      default: '--'
    },
    thresoldWbgt1: {
      type: Number,
      default: 28
    },
    thresoldWbgt2: {
      type: Number,
      default: 30
    },
    thresoldWbgt3: {
      type: Number,
      default: 32
    },
    thresoldHeat1: {
      type: Number,
      default: 32
    },
    thresoldHeat2: {
      type: Number,
      default: 40
    },
    thresoldHeat3: {
      type: Number,
      default: 60
    }
  },
  computed: {
    cssPropsGauge () {
      return {
        width: '35px',
        '--rotation': `${this.lastWBGT * 180 / 50}deg`, // 50 === Max value (arbitrarily selected)
        '--color': this.colorGauge
      }
    },
    colorGauge () {
      if (!this.lastWBGT) return '#FFF'
      if (this.lastWBGT >= this.thresoldWbgt3) {
        return 'var(--color-red)'
      } else if (this.lastWBGT >= this.thresoldWbgt2) {
        return 'var(--color-orange)'
      } else if (this.lastWBGT >= this.thresoldWbgt1) {
        return 'var(--color-yellow)'
      } else {
        return 'var(--color-green)'
      }
    },
    cssPropsHeat () {
      return {
        color: this.colorHeat
      }
    },
    colorHeat () {
      if (!this.avgHeatIndex) return '#FFF'
      if (this.avgHeatIndex >= this.thresoldHeat3) {
        return 'var(--color-red)'
      } else if (this.lastWBGT >= this.thresoldHeat2) {
        return 'var(--color-orange)'
      } else if (this.lastWBGT >= this.thresoldHeat1) {
        return 'var(--color-yellow)'
      } else {
        return 'var(--color-green)'
      }
    }
  }
})

